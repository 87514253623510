var $ = (window.jQuery = require('jquery'));

module.exports = function(sel) {
	var $el = $(sel);

	if (!localStorage.cookiesAccepted) {
		$el
			.show()
			.find('.box')
			.click(function() {
				$el.hide();
				localStorage.cookiesAccepted = true;
			});
	}
};
