var $ = (window.jQuery = require('jquery'));

function QuoteForm(elem) {
	var $me = $(elem);
	var $productInput = $me.find('.js-product');
	var $thicknessContainers = $me.find('.thickness-container');
	var $cornerInput = $me.find('.corners-container input');
	var $lengths = $me.find('.length-container .input');
	var $total = $me.find('p.price');
	var $form = $me.find('form');
	var $resetBtn = $me.find('.resetPrice');
	var $comments = $me.find('.quote-comment');
	var $response = $me.find('.quote-response');

	function updateLengths() {
		var corners = parseInt($cornerInput.val(), 10);
		corners = isNaN(corners) ? 0 : corners;
		$lengths.each(function(i) {
			this.hidden = i > corners;
		});
	}

	function updateProduct() {
		var product = $productInput.val();
		$me.find('.js-depends').hide().find(':input').prop('disabled', true);
		$me.find('.js-depends-' + product).show().find(':input').prop('disabled', false);
		resetPrice();
	}

	function resetPrice() {
		$total.text('00.00');
		$comments.hide();
	}

	$cornerInput.on('change', updateLengths);
	$productInput.change(updateProduct);

	$thicknessContainers.each(function() {
		var $me = $(this);
		var $input = $me.find('select');
		var $message = $me.find('.thickness-message');

		$input.change(function() {
			$message.show(!!$input.val());
		});
	});

	updateLengths();
	updateProduct();

	$form.on('ajaxform.success', function(e, data) {
		var total = data.total.toString();
		$total.text(total.substr(0, total.length - 2) + '.' + total.substr(-2));
		$comments.show();
		$response.text(data.comment);
	});

	$resetBtn.click(resetPrice);
}

module.exports = function(sel) {
	$(sel).each(function() {
		QuoteForm(this);
	});
};
