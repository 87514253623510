require('../img/about/about-full.jpg');
require('../img/about/about-square.jpg');
require('../img/accreditations/chas.png');
require('../img/accreditations/cqms.png');
require('../img/banners/about-us.jpg');
require('../img/banners/blogs.jpg');
require('../img/banners/contact-us.jpg');
require('../img/banners/legal.jpg');
require('../img/banners/products.jpg');
require('../img/banners/services.jpg');
require('../img/banners/team.jpg');
require('../img/banners/testimonials.jpg');
require('../img/client-logo.jpg'); // TODO: looks like a placeholder
require('../img/easyfix-product.jpg');
require('../img/favicon.png');
require('../img/homepage/slider/slide-1.jpg');
require('../img/homepage/slider/slide-3.jpg');
require('../img/homepage/slider/slide-4.jpg');
require('../img/icons/3d.svg');
require('../img/icons/account-dark-grey.svg');
require('../img/icons/close-dark-grey.svg');
require('../img/icons/email-white.svg');
require('../img/icons/facebook-dark-grey.svg');
require('../img/icons/facebook-grey.svg');
require('../img/icons/facebook.png');
require('../img/icons/instagram-dark-grey.svg');
require('../img/icons/instagram.png');
require('../img/icons/linkedin-darkgrey.svg');
require('../img/icons/linkedin.png');
require('../img/icons/logo-dark.svg');
require('../img/icons/logo-light.svg');
require('../img/icons/menu-close-dark-grey.svg');
require('../img/icons/menu-white.svg');
require('../img/icons/planner.svg');
require('../img/icons/print-grey.svg');
require('../img/icons/search-dark-grey.svg');
require('../img/icons/telephone-white.svg');
require('../img/icons/twitter-dark-grey.svg');
require('../img/icons/twitter-grey.svg');
require('../img/icons/twitter.png');
require('../img/login/support-icon.jpg');
require('../img/planner.svg');
require('../img/services/3d.svg');
require('../img/services/bespoke.svg');
require('../img/services/contractor.svg');
require('../img/services/gallery.svg');
require('../img/services/home-owner.svg');
require('../img/services/project.svg');
require('../img/services/services-approach.jpg');
require('../img/services/services-difference.jpg');
require('../img/services/services-full.jpg');
require('../img/services/services-planner.jpg');
require('../img/services/services-service.jpg');
require('../img/services/services-team.jpg');
require('../img/team/david.jpg');
require('../img/team/glenn.jpg');
require('../img/team/lizzie.jpg');
require('../img/team/stevenl.jpg');
require('../img/team/stevenr.jpg');
