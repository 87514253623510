/* global gtag, PathPrefix */
require('@babel/polyfill');

var $ = (window.jQuery = require('jquery'));
require('slick-carousel');
require('./jquery.lightbox_me');
require('cefar-superbox');

var ajaxForm = require('./ajax-form');
var quoteForm = require('./quote-form');
var viewMore = require('./view-more');
var cookiePopup = require('./cookie-popup');
var PlannerContainer = require('balcony-3d-test/src/PlannerContainer');

require('../scss/style.scss');
require('balcony-3d-test/src/style.scss');
require('./email-resources.js');
require('./script-resources.js');

function returnTop() {
	$('body,html').animate(
		{
			scrollTop: 0,
		},
		500
	);
}

function toggleAccordion() {
	$(this)
		.parent()
		.parent()
		.toggleClass('active');
}

function toggleMobileMenu() {
	$('.navigation').toggleClass('mobile');
}

function mobileMenuAccordion() {
	$(this).toggleClass('active');
}

function gaTriggers() {
	gtag('event', 'click', {
		event_category: $(this).data('ga-category'),
		event_label: $(this).data('ga-label'),
	});
}

function triggerCase() {
	var ID = $(this).data('case');
	$('.main-block-wrapper').removeClass('active');
	$(ID).addClass('active');
	$('.trigger-case').removeClass('active');
	$(this).addClass('active');

	var nav = $('.top-bar-navigation');

	$('html').animate({
		scrollTop: $('.main-block-wrapper').offset().top - (nav.height() + 10),
	});
	return false;
}

function scrollTerms(e) {
	e.preventDefault();
	$('html, body').animate({
		scrollTop:
			$($(this).attr('href')).offset().top -
			($('.top-bar-navigation').height() + 10),
	});
}

function init3dPlanner(sel) {
	var $container = $(sel);
	if ($container.length) {
		new PlannerContainer($container[0], {
			thanksP2:
				'In the meantime you can browse our installations or get in touch with any queries you may have.',
			thanksUrl1: '/contact',
			thanksUrl2: '/',
		}).addQuoteListener(function(pc) {
			$.post(PathPrefix + 'planner/submit', pc.getQuoteData(), function(data) {
				if (data.success) {
					if (window.gtag) {
						window.gtag('event', 'submit', {
							event_category: 'Form',
							event_label: '/planner/submit',
						});
					}
				}
			});
		});
	}
}

$(document).ready(function() {
	$('.is-dropdown').click(mobileMenuAccordion);
	$('.menu-toggle').click(toggleMobileMenu);
	$('.accordion .title').click(toggleAccordion);
	$('.return-top').click(returnTop);

	$('.trigger-case').click(triggerCase);
	$('.trigger-case')
		.first()
		.addClass('active');

	$('.js-ga-track').click(gaTriggers);
	$('.scroll-hash').click(scrollTerms);

	$('.home-slider')
		.slick({
			dots: true,
			arrows: true,
			autoplay: true,
			autoplaySpeed: 12000,
			pauseOnFocus: false,
			pauseOnHover: false,
		})
		.each(function() {
			$(this)
				.append(
					(this.slick.$currentPage = $('<div class="current-page"></div>'))
				)
				.on('beforeChange', updateSliderPage);
			updateSliderPage(null, this.slick);
		});

	$('.case-study-gallery .gallery-slider')
		.slick({
			// centerMode: true,
			slidesToShow: 2.5,
			infinite: true,
			arrows: true,
			dots: false,
			focusOnSelect: false,
			autoplay: true,
			draggable: false,
			autoplaySpeed: 4000,
			// initialSlide: 1,
			responsive: [
				{
					breakpoint: 769,
					settings: {
						slidesToShow: 2,
					},
				},
				{
					breakpoint: 501,
					settings: {
						slidesToShow: 1,
					},
				},
			],
		})
		.each(function() {
			$(this)
				.append(
					(this.slick.$currentPage = $('<div class="current-page"></div>'))
				)
				.on('beforeChange', updateSliderPage);
			updateSliderPage(null, this.slick);
		});

	function updateSliderPage(e, slick, currentSlide, nextSlide) {
		var slide =
			typeof nextSlide === 'undefined' ? slick.currentSlide : nextSlide;
		var total = slick.$slides.length;
		if (total <= 1) {
			return;
		}
		slick.$currentPage.text(slide + 1 + '/' + total);
	}
	$('.testimonial-slider')
		.slick({
			dots: true,
			arrows: true,
			autoplay: true,
			autoplaySpeed: 15000,
		})
		.each(function() {
			$(this)
				.append(
					(this.slick.$currentPage = $('<div class="current-page"></div>'))
				)
				.on('beforeChange', updateSliderPage);
			updateSliderPage(null, this.slick);
		});

	$('.accreditations-slider').slick({
		dots: false,
		arrows: false,
		slidesToShow: 6,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 1000,
		responsive: [
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 4,
				},
			},
			{
				breakpoint: 551,
				settings: {
					slidesToShow: 3,
				},
			},
			{
				breakpoint: 451,
				settings: {
					slidesToShow: 2,
				},
			},
		],
	});

	$('body').on('click', '.trigger-gallery', function() {
		var $data = $(this).data('gallery');

		$($data).lightbox_me({
			closeSelector: '.close',
			centered: true,
			overlayCSS: { background: 'black', opacity: 0.75 },
		});
	});

	$('.toggle-quote').click(function() {
		$('.quote-block').toggleClass('active');
	});

	viewMore('.js-view-more');
	quoteForm('.quote-block');
	ajaxForm('.js-ajax-form');

	$('.accordion-block .content').each(function() {
		var h = $(this).height();
		$(this)
			.height(h)
			.addClass('noHeight');
	});

	$('.accordion .dropdown .title').click(function() {
		$(this)
			.next('.content')
			.toggleClass('noHeight');
	});

	cookiePopup('.cookie-popup');
	init3dPlanner('.js-planner-container');
});

$(window).on('scroll', function() {
	if ($('.off-screen-trigger').length) {
		var top = $(window).scrollTop(),
			divBottom =
				$('.off-screen-trigger').offset().top +
				$('.off-screen-trigger').outerHeight();
		if (divBottom > top + 126) {
			$('.top-bar-navigation').removeClass('shrink');
		} else {
			$('.top-bar-navigation').addClass('shrink');
		}
	}
});
