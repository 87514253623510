var $ = (window.jQuery = require('jquery'));
require('jsrender');

function ViewMore(elem) {
	var $me = $(elem);
	var $cont = $(elem.dataset.viewmoreContainer);
	var tmpl = $.templates(elem.dataset.viewmoreTemplate);
	var src = elem.dataset.viewmoreSource;
	var page = 0;

	$me.click(function(e) {
		e.preventDefault();

		$.getJSON(src + page, function(data) {
			if (data.more) {
				page++;
			} else {
				$me.hide();
			}

			$cont.append(tmpl(data.items));
		});
	});
}

module.exports = function(sel) {
	$(sel).each(function() {
		ViewMore(this);
	});
};
