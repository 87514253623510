var $ = (window.jQuery = require('jquery'));

function fieldConcat(prefix, key) {
	return prefix + '[' + key + ']';
}

function getError($field) {
	var $error = $field.next();

	if (!$error.length) {
		$error = $('<div class="error-message"></div>').insertAfter($field);
	}

	return $error;
}

function AjaxForm(elem) {
	var $me = $(elem);
	var needsRecaptchaToken = $me.hasClass('js-use-recaptcha');
	console.log($me, needsRecaptchaToken);

	var url = $me.attr('action');
	var $generalError = $me.find('.general-error');
	if (!$generalError.length) {
		$generalError = $(
			'<div class="general-error error-message"></div>'
		).appendTo($me);
	}

	var $message = $me.find('.message');
	if (!$message.length) {
		$message = $('<div class="message"></div>').prependTo($me);
	}

	var $fields = $me.find('input[id], select[id], textarea[id]');

	function applyErrors(errors, prefix) {
		Object.entries(errors).forEach(function(e) {
			var key = e[0];
			var val = e[1];
			var name = fieldConcat(prefix, key);

			if (key === '_general') {
				$generalError.text(val);
			} else if (Array.isArray(val)) {
				var $field = $fields.filter("[name='" + name + "']");

				if ($field.length) {
					$field.addClass('error');
					getError($field).text('Please check this field.');
				}
			} else {
				applyErrors(val, name);
			}
		});
	}

	function postForm(data) {
		$.post(url, data, function(r) {
			if (r.success) {
				$message.addClass('success');
				$me.trigger('ajaxform.success', r);
				if($('#ContactIndexForm').length > 0) {
					$('#ContactIndexForm').trigger("reset");
				}
				if (window.gtag) {
					window.gtag('event', 'submit', {
						event_category: 'Form',
						event_label: url,
					});
				}
			} else {
				$message.addClass('error');
				$me.trigger('ajaxform.failure', r);

				applyErrors(r.errors, 'data');
			}

			$message.text(r.message);
		});
	}

	$me.submit(function(e) {
		e.preventDefault();
		$fields.removeClass('error');
		$message.text('').removeClass('success error');
		$me.find('.error-message').text('');
		var data = $me.serializeArray();
		console.log(data);

		if (needsRecaptchaToken) {
			if (grecaptcha) {
				grecaptcha.ready(function() {
					grecaptcha.execute($me.data('site-key'), {action: 'submit'}).then(function(token) {
						data.push({ name: 'data[g-recaptcha-response]', value: token });
						postForm(data);
					});
				});
			} else {
				alert('ReCaptcha library failed to load; check console.');
			}
		} else postForm(data);
	});
}

module.exports = function(sel) {
	$(sel).each(function() {
		AjaxForm(this);
	});
};
